import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
// Icons
import { IoDiamond, IoLogoEuro, IoHammer } from "react-icons/io5";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const About = () => {
  return (
    <Layout>
      <Seo
        title="Sobre Nosotros - BIKESAFE"
        description="Bike Safe diseña y fabrica soluciones de aparcabicicletas de alta calidad. Ofrecemos un servicio integral, incluyendo instalación y productos innovadores hechos en el Reino Unido."
      />
      <Container>
        <Row className="mt-5 mb-5 text-center">
          <Col sm="12">
            <h1>Sobre Nosotros</h1>
            <h3>
              ¿Quiénes somos? Somos su proveedor integral de soluciones de
              aparcabicicletas.
            </h3>
            <StaticImage
              placeholder="blurred"
              src="../images/about-header.jpg"
              className="mt-4 mb-4 shadow fluid rounded"
              alt="Bikesafe Fabrication"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <h2 className="float-lg-left subheading-product">
              Nuestros Objetivos:
            </h2>
          </Col>
          <Col lg="6" sm="12">
            <p className="flow-text">
              Diseñamos y fabricamos muchos de los productos que ofrecemos,
              proporcionando un servicio integral que incluye la instalación
              profesional para todos nuestros productos.
            </p>
            <ul>
              <li className="border p-2 shade-on-hover">
                Ofrecemos una gama completa de productos, desde prácticos
                aparcabicicletas tipo Sheffield hasta marquesinas y soluciones
                de estacionamiento para todo tipo de instalaciones.
              </li>
              <li className="border p-2 shade-on-hover">
                Somos proveedores oficiales de TFL y trabajamos con empresas,
                centros educativos, contratistas, autoridades locales y clientes
                residenciales.
              </li>
              <li className="border p-2 shade-on-hover">
                Ofrecemos aparcabicicletas de alta calidad con opciones de
                instalación en oficinas, espacios públicos y centros
                comerciales.
              </li>
              <li className="border p-2 shade-on-hover">
                Nuestro objetivo es fabricar productos basados en la calidad,
                combinando tradición con un diseño innovador y un enfoque
                centrado en el cliente.
              </li>
              <li className="border p-2 shade-on-hover">
                Somos la primera empresa que fabrica aparcabicicletas utilizando
                acero reciclado: descubra más en nuestra sección de reciclaje.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-5 mb-5 text-center">
          <Col sm="12">
            <h3>
              Conozca cómo fabricamos nuestros aparcabicicletas en nuestra
              fábrica del Reino Unido:
            </h3>
          </Col>
        </Row>
        <Row className="text-center">
          <Col lg="4" sm="12">
            <StaticImage
              placeholder="blurred"
              src="../images/factory/1.jpg"
              alt="Bikesafe Fabrication"
              className="mt-3 fluid rounded"
            />
          </Col>
          <Col lg="4" sm="12">
            <StaticImage
              placeholder="blurred"
              src="../images/factory/2.jpg"
              alt="Bikesafe Fabrication"
              className="mt-3 fluid rounded"
            />
          </Col>
          <Col lg="4" sm="12">
            <StaticImage
              placeholder="blurred"
              src="../images/factory/3.jpg"
              alt="Bikesafe Fabrication"
              className="mt-3 fluid rounded"
            />
          </Col>
          <Col id="offsetColumnOne" className="d-none d-lg-block" lg="2" />
          <Col lg="4" sm="12">
            <StaticImage
              placeholder="blurred"
              src="../images/factory/4.jpg"
              alt="Bikesafe Fabrication"
              className="mt-3 fluid rounded"
            />
          </Col>
          <Col lg="4" sm="12">
            <StaticImage
              placeholder="blurred"
              src="../images/factory/5.jpg"
              alt="Bikesafe Fabrication"
              className="mt-3 fluid rounded"
            />
          </Col>
          <Col id="offsetColumnTwo" className="d-none d-lg-block" lg="2" />
        </Row>
        <Row className="mt-5 mb-5 text-center">
          <Col sm="12">
            <h3>
              Todos nuestros productos comparten características esenciales para
              cualquier mobiliario urbano:
            </h3>
            <p className="flow-text mt-5">
              <IoDiamond /> Alta calidad
            </p>
            <p className="flow-text mt-5">
              <IoHammer /> Mantenimiento mínimo
            </p>
            <p className="flow-text mt-5">
              <IoLogoEuro /> Excelente relación calidad-precio
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;
